import gql from 'graphql-tag'


const listResponse = `
  id code name
  parentId children {
    id code name parentId
  }
`

export const LIST_MANAGE = (templateType) => gql`query LIST_MANAGE ($manageType: String!, $q: FilterInput) {
  manages: listManage${templateType} (manageType: $manageType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id code name parentId
  categories {id name}
`

export const DETAIL_MANAGE = (templateType) => gql`query DETAIL_MANAGE ($manageType: String!, $manageId: Int!) {
  manage: detailManage${templateType} (manageType: $manageType, manageId: $manageId) {${detailResponse}}
}`

export const CREATE_MANAGE = (templateType) => gql`mutation CREATE_MANAGE ($manageType: String!, $input: Manage${templateType}Input!) {
  createManage: createManage${templateType} (manageType: $manageType, input: $input) {${detailResponse}}
}`

export const UPDATE_MANAGE = (templateType) => gql`mutation UPDATE_MANAGE ($manageType: String!, $manageId: Int!, $input: Manage${templateType}Input!) {
  updateManage: updateManage${templateType} (manageType: $manageType, manageId: $manageId, input: $input) {${detailResponse}}
}`

export const DESTROY_MANAGE = (templateType) => gql`mutation DESTROY_MANAGE ($manageType: String!, $manageId: Int!) {
  destroyManage: destroyManage${templateType} (manageType: $manageType, manageId: $manageId) {id}
}`

export const DROPDOWN_PARENTS = (templateType) => gql`query DROPDOWN_PARENTS ($manageType: String!, $q: FilterInput) {
  items: listManage${templateType}DropdownParent (manageType: $manageType, q: $q) {
    id code name
  }
}`

export const LIST_LEDGER = (templateType) => gql`query LIST_LEDGER ($manageType: String!, $manageIds: [Int!]!, $startDate: Date, $endDate: Date) {
  items: listManage${templateType}Ledger (manageType: $manageType, manageIds: $manageIds, startDate: $startDate, endDate: $endDate) {
    manageId debit credit total
  }
}`
