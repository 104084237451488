import gql from 'graphql-tag'


export const LIST_CATEGORY = (templateType) => gql`query LIST_CATEGORY ($manageType: String!) {
  categories: listManage${templateType}Category (manageType: $manageType) {
    id type code name
  }
}`

export const DETAIL_CATEGORY = (templateType) => gql`query DETAIL_CATEGORY ($manageType: String!, $categoryId: Int!) {
  category: detailManage${templateType}Category (manageType: $manageType, categoryId: $categoryId) {
    id type code name
  }
}`

export const CREATE_CATEGORY = (templateType) => gql`mutation CREATE_CATEGORY ($manageType: String!, $input: ManageCategoryInput!) {
  createCategory: createManage${templateType}Category (manageType: $manageType, input: $input) {
    id type code name
  }
}`

export const UPDATE_CATEGORY = (templateType) => gql`mutation UPDATE_CATEGORY ($manageType: String!, $categoryId: Int!, $input: ManageCategoryInput!) {
  updateCategory: updateManage${templateType}Category (manageType: $manageType, categoryId: $categoryId, input: $input) {
    id type code name
  }
}`

export const DESTROY_CATEGORY = (templateType) => gql`mutation DESTROY_CATEGORY ($manageType: String!, $categoryId: Int!) {
  destroyCategory: destroyManage${templateType}Category (manageType: $manageType, categoryId: $categoryId) {
    id
  }
}`
