<template>
  <div>
    <ul class="tree">
      <ListFolderItem
        v-for="item in treeview"
        :item="item"
        :key="item.id"
        :manageType="manageType"
        :templateType="templateType"
        @bus="$emit('selected', $event)">
      </ListFolderItem>
    </ul>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import ListFolderItem from './ListFolderItem'
import { LIST_MANAGE } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    manageType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Manage${this.$form.capitalize(this.manageType)}ListFolder`,
      manages: [],
    }
  },
  apollo: {
    manages: {
      query () {
        return LIST_MANAGE(this.templateType)
      },
      variables () {
        return {
          manageType: this.manageType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  computed: {
    treeview () {
      return this.manages.filter(v => !v.parentId).map((v, i) => this.mapItem(v, i, this.manages))
    },
  },
  methods: {
    getFilter (v) {
      return {
        ...v,
        order: 'code',
        limit: -1
      }
    },
    mapItem (item, index, arr) {
      return {
        ...item,
        children: arr.filter(v => v.parentId === item.id).map((v, i) => this.mapItem(v, i, arr))
      }
    },
  },
  components: {
    ListFolderItem
  }
}
</script>

<style lang="css" scoped>
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  position: relative;
  margin: 0;
  padding: 0;

  border: 0 none;

  -webkit-transition: all ease .4s;
  -moz-transition: all ease .4s;
    transition: all ease .4s;
}

ul.tree, ul.tree ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.tree ul {
  margin-left: 10px;
}
ul.tree li {
  margin: 0;
  padding: 0 7px;
  line-height: 20px;
  border-left:1px solid rgb(100,100,100);
}
ul.tree li:last-child {
  border-left:none;
}
ul.tree li:before {
  position:relative;
  top:-0.3em;
  height:1em;
  width:12px;
  color:white;
  border-bottom:1px solid rgb(100,100,100);
  content:"";
  display:inline-block;
  left:-7px;
}
ul.tree li:last-child:before {
  border-left:1px solid rgb(100,100,100);
}
</style>
