<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>

      <div class="dashhead-toolbar">
        <div class=" dashhead-toolbar-item">
          <Category
            :options="categories"
            :manageType="manageType"
            :templateType="templateType"
            v-model="categoryId">
          </Category>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <ListFolder
      v-if="categoryId === -2"
      :manageType="manageType"
      :templateType="templateType">
    </ListFolder>

    <ListTable
      v-else
      :group="group"
      :categoryId="categoryId"
      :manageType="manageType"
      :templateType="templateType">
    </ListTable>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import ListFolder from './ListFolder.vue'
import ListTable from './ListTable.vue'
import Category from './Category.vue'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.subTitle}-${this.title}`
    }
  },
  props: {
    templateType: {
      type: String,
      required: true
    },
    manageType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Manage${this.$form.capitalize(this.manageType)}List`,
      rFields: ['categoryId'],
      categoryId: 0,
      categories: [
        {text: 'แผนผัง', value: -2},
      ]
    }
  },
  watch: {
    categoryId () {
      this.setRetaining()
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    ListFolder,
    ListTable,
    Category
  }
}
</script>

<style lang="css" scoped>
</style>
