<template>
  <sgv-table
    :rKey="rKey"
    :items="manageMapped"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options"
    :rowsPerPage="rowsPerPage"
    :toolbars="toolbars">

    <div
      slot="option"
      v-if="options.toolbar === 'ledger'"
      class="mb-3">
      <div class="form-row">
        <sgv-input-date
          label="เริ่มต้น"
          class="col-6"
          isNull
          v-model="ledgerFormdata.startDate">
        </sgv-input-date>

        <sgv-input-date
          label="สิ้นสุด"
          class="col-6"
          isNull
          v-model="ledgerFormdata.endDate">
        </sgv-input-date>
      </div>

      <button
        type="button"
        class="btn btn-warning"
        @click="fetchLedger">
        ค้นหา
      </button>
    </div>

    <template slot-scope="{item, hidden}">
      <tr>
        <td v-if="hidden.code">
          <router-link
            class="text-decoration-none"
            :to="toDetail(item.id)">
            {{item.code}}
          </router-link>
        </td>
        <td v-if="hidden.name">{{ item.name }}</td>
        <td v-if="hidden.debit" class="text-success">{{ item.debit }}</td>
        <td v-if="hidden.credit" class="text-danger">{{ item.credit }}</td>
        <td v-if="hidden.total">{{ item.total }}</td>
      </tr>
    </template>

    <router-link
      slot="action"
      v-if="$auth.hasRole(`manage:${manageType}:add`)"
      :to="toDetail(0)">
      <button
        type="button"
        class="btn btn-link text-success">
        เพิ่ม
      </button>
    </router-link>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_MANAGE,
  LIST_LEDGER
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    manageType: {
      type: String,
      required: true
    },
    categoryId: {
      type: Number,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `Manage${this.$form.capitalize(this.manageType)}Detail`,
      rKey: `Manage${this.$form.capitalize(this.manageType)}ListTable`,
      rFields: ['filter', 'options', 'ledgerFormdata'],
      headers: [
        {text: 'รหัส', value: 'code', sort: true, filter: true},
        {text: 'ชื่อ', value: 'name', sort: true, filter: true},
        {text: 'เดบิต', value: 'debit'},
        {text: 'เครดิต', value: 'credit'},
        {text: 'รวม', value: 'total'},
      ],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name', 'debit', 'credit', 'total'],
        column: null,
        search: null,
        toolbar: null,
      },
      manages: [],
      rowsPerPage: [
        {text: '10', value: 10},
        {text: '25', value: 25},
        {text: '50', value: 50},
      ],
      toolbars: [
        {value: 'filter', icon: 'cog', class: 'text-warning'},
        {value: 'ledger', icon: 'file-invoice-dollar', class: 'text-info'}
      ],
      ledgerFormdata: {
        startDate: '',
        endDate: ''
      },
      ledgers: []
    }
  },
  apollo: {
    manages: {
      query () {
        return LIST_MANAGE(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          manageType: this.manageType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  computed: {
    manageMapped () {
      return this.manages.map(acc => {
        const ledger = this.ledgers.find(led => led.manageId === acc.id)
        return {
          id: acc.id,
          code: acc.code,
          name: acc.name,
          debit: ledger?.debit,
          credit: ledger?.credit,
          total: ledger?.total,
        }
      })
    }
  },
  methods: {
    getFilter (v) {
      const filter = {...v, params: v.params || {}}
      if (this.categoryId === -1) {
        filter.params.isActive = false
        delete filter.params.categoryId
      } else {
        filter.params.isActive = true
        filter.params.categoryId = this.categoryId
      }
      return filter
    },
    toDetail (id) {
      return {
        name: this.detailView,
        params: {manageId: id}
      }
    },
    fetchLedger () {
      const manageIds = this.manages.map(v => v.id)

      if (manageIds.length === 0) return

      this.ledgers = []

      this.$apollo.query({
        query: LIST_LEDGER(this.templateType),
        variables: {
          manageType: this.manageType,
          manageIds,
          startDate: this.ledgerFormdata.startDate ? this.ledgerFormdata.startDate : null,
          endDate: this.ledgerFormdata.endDate ? this.ledgerFormdata.endDate : null
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.ledgers = res.data.items
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  },
  watch: {
    ledgerFormdata: 'setRetaining'
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  }
}
</script>

<style lang="css" scoped>
</style>
