<template>
  <li>
    {{item.code}} ({{item.name}})
    <router-link
      :to="toDetail(item.id)"
      class="text-decoration-none">
      <fa icon="info" class="ml-1 text-primary"></fa>
    </router-link>
    <ul
      v-if="item.children && item.children.length > 0">
      <list-link-item
        v-for="child in item.children"
        :item="child"
        :key="child.id"
        :manageType="manageType"
        :templateType="templateType"
        @bus="bus">
      </list-link-item>
    </ul>
  </li>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    manageType: {
      type: String,
      required: true
    }
  },
  name: 'list-link-item',
  data () {
    return {
      detailView: `Manage${this.$form.capitalize(this.manageType)}Detail`
    }
  },
  methods: {
    toDetail (id) {
      return {
        name: this.detailView,
        params: {manageId: id}
      }
    },
    bus (value) {
      this.$emit('bus', value)
    }
  },
}
</script>

<style lang="css" scoped>
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  position: relative;
  margin: 0;
  padding: 0;

  border: 0 none;

  -webkit-transition: all ease .4s;
  -moz-transition: all ease .4s;
    transition: all ease .4s;
}

ul.tree, ul.tree ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.tree ul {
  margin-left: 10px;
}
ul.tree li {
  margin: 0;
  padding: 0 7px;
  line-height: 20px;
  border-left:1px solid rgb(100,100,100);
}
ul.tree li:last-child {
  border-left:none;
}
ul.tree li:before {
  position:relative;
  top:-0.3em;
  height:1em;
  width:12px;
  color:white;
  border-bottom:1px solid rgb(100,100,100);
  content:"";
  display:inline-block;
  left:-7px;
}
ul.tree li:last-child:before {
  border-left:1px solid rgb(100,100,100);
}
</style>
